<template>
  <div class="vol-page">
    <div class="vol-linear"></div>
    <div class="container">
      <div class="vol-banner">
        <div>
          <span class="vol-event-name">{{ info.orga_name }}</span>
          <div class="vol-event-intro">
            <div class="intro-item">
              <div><i class="iconfont icon-weibiaoti-3"></i>所在地区</div>
              &nbsp;&nbsp;
              <div>{{ info.city + info.district }}</div>
            </div>
            <div class="intro-item">
              <div><i class="iconfont icon-geren"></i>负责人</div>
              &nbsp;&nbsp;
              <div>{{ info.real_name }}</div>
            </div>
          </div>
        </div>
        <img src="../../assets/volunteer/hearts.png" class="vol-hearts" />
        <img src="../../assets/volunteer/org.png" class="vol-org" />
      </div>
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item">
              <a @click="$router.back(-1)" href="javascript:void()">
                组织列表</a
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">组织详情</li>
          </ol>
        </nav>

        <ul class="nav nav-tabs nav-red">
          <li class="nav-item">
            <button
              class="nav-link active"
              data-bs-toggle="tab"
              data-bs-target="#org-tab-1"
              type="button"
              aria-selected="true"
            >
              组织简介
            </button>
          </li>
          <li class="nav-item">
            <button
              class="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#org-tab-2"
              type="button"
              aria-selected="false"
            >
              精彩活动
            </button>
          </li>
        </ul>
        <div class="tab-content">
          <div
            class="tab-pane fade show active"
            id="org-tab-1"
            role="tabpanel"
            tabindex="0"
          >
            <div class="rich-text" v-html="decodeContent(info.intro)"></div>

            <div class="footer-bottom">
              <div class="left">
                <button class="btn btn-link" @click="toFavourite">
                  <i
                    :class="[
                      'iconfont',
                      info.is_favourite == 1
                        ? 'icon-favourite_selected'
                        : 'icon-favourite_normal',
                    ]"
                  ></i>
                </button>
              </div>
              <!-- 加入组织 -->
              <div class="right" v-if="info.member_id != userInfo.member_id">
                <span class="state-fail" v-if="info.apply_status == -1"
                  >未通过审核</span
                >
                <div
                  class="bottom-btn"
                  @click="toPersonLogin"
                  v-if="info.apply_status == 0 || info.apply_status == -1"
                >
                  加入组织
                </div>
                <span class="state state-ing" v-if="info.apply_status == 1"
                  >审核中</span
                >
                <div
                  class="bottom-btn"
                  @click="toPersonLogin"
                  v-if="info.apply_status == 2"
                >
                  通过审核
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="org-tab-2"
            role="tabpanel"
            tabindex="0"
          >
            <div class="vol-news-list">
              <div
                class="vol-news-item"
                v-for="item in eventData.list"
                :key="item.event_id"
                @click="toEventDetail(item)"
              >
                <div class="vol-news-pic">
                  <img :src="item.image" />
                </div>
                <span class="vol-news-title">{{ item.title }}</span>
                <span class="vol-news-time">{{ item.created_at }}</span>
              </div>
            </div>
            <Pagination
              @change="pageChanged"
              :current="1"
              :pageSize="100"
              :hideOnSinglePage="true"
              :total="parseInt(eventData.total)"
              :showQuickJumper="false"
              :showTotal="false"
              placement="center"
            />
            <!-- <PageNav @pageChanged="pageChanged"
                     :max="calcMaxPage(eventData.total)"
                     ref="page" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  colunteerDetail,
  eventList,
  joinGroup,
  colunteerFavourtie,
} from "@/network/API.js";
import Pagination from "@/components/Pagination.vue";
import SearchBar from "@/components/SearchBar.vue";
export default {
  components: {
    SearchBar,
    Pagination,
  },
  data() {
    return {
      userInfo: this.$store.getters.userInfo,
      pageNumber: 1,
      info: {},
      eventData: {},
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.getVolunteerDetail(this.$route.params.id);
      // let data = await colunteerDetail(this.$route.params.id);
      // this.info = data;

      // this.eventData = await eventList({
      //   volunteer: this.$route.params.id,
      //   page_size: 100,
      // });
    }
  },
  methods: {
    async toFavourite() {
      let res = await colunteerFavourtie({
        id: this.info.volunteer_group_id,
      });
      if (res.is_favourite == 0) {
        this.$toast("取消收藏");
      } else {
        this.$toast("收藏成功");
      }
      this.info.is_favourite = res.is_favourite;
    },
    async getVolunteerDetail(oid) {
      let data = await colunteerDetail(this.$route.params.id);
      this.info = data;

      this.eventData = await eventList({
        volunteer: this.$route.params.id,
        page_size: 100,
      });
    },
    async toPersonLogin() {
      if (this.userInfo.is_volunteer != "1") {
        this.$toast("请先成为志愿者");
        return;
      }
      let loader = this.$loading.show();
      await joinGroup({
        gorup_id: this.userInfo.volunteer_group_id,
      });
      loader.hide();
      this.$toast("已申请, 请等待审核");
      this.info.apply_status = 1;
    },
    pageChanged(val) {
      this.pageNumber = val.page;
      this.getDataList();
    },
  },
};
</script>
<style>
.vol-hearts {
  top: -20px;
}
.vol-org {
  position: absolute;
  z-index: 3;
  right: -10px;
  top: -18px;
  width: 326px;
  height: 295px;
}
.vol-event-cover {
  position: relative;
  z-index: 2;
  margin-bottom: -30px;
}

.vol-linear {
  height: 360px;
}

.vol-event-cover image {
  display: block;
  width: 750px;
  height: 390px;
}

.vol-event-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #9e9e9e;
  margin-bottom: 30px;
}

.vol-event-intro {
  position: relative;
  z-index: 2;
  padding: 20px 0 0 0;
  font-size: 18px;
  color: #fff;
  /* margin-bottom: 30px;
  margin-top: -30px; */
}

.intro-item {
  display: flex;
  align-items: center;

  margin-bottom: 10px;
}

.intro-item:last-child {
  margin-bottom: 0;
}

.intro-item .iconfont {
  margin-right: 5px;
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-bottom .left,
.footer-bottom .right {
  display: flex;
  align-items: center;
}

.footer-bottom .right {
  flex: 1;
  justify-content: flex-end;
}

.footer-bottom .iconfont {
  font-size: 38px;
  color: #ff3241;
  background: linear-gradient(123deg, #ffb990 1%, #ff3241 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.footer-bottom .bottom-btn {
  width: 260px;
  background: linear-gradient(123deg, #ffb990 1%, #ff3241 100%);
  margin-left: 15px;
  border-radius: 50px;
}

.footer-bottom .right .state {
  white-space: nowrap;
  font-size: 18px;
  color: #ff3241;
}
.footer-bottom .right .state.state-success {
  color: #00be6e;
}
.btn-link {
  text-decoration: none;
}
.disabled {
  opacity: 0.5;
}
</style>
