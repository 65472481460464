var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vol-page"},[_c('div',{staticClass:"vol-linear"}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"vol-banner"},[_c('div',[_c('span',{staticClass:"vol-event-name"},[_vm._v(_vm._s(_vm.info.orga_name))]),_c('div',{staticClass:"vol-event-intro"},[_c('div',{staticClass:"intro-item"},[_vm._m(0),_vm._v("    "),_c('div',[_vm._v(_vm._s(_vm.info.city + _vm.info.district))])]),_c('div',{staticClass:"intro-item"},[_vm._m(1),_vm._v("    "),_c('div',[_vm._v(_vm._s(_vm.info.real_name))])])])]),_c('img',{staticClass:"vol-hearts",attrs:{"src":require("../../assets/volunteer/hearts.png")}}),_c('img',{staticClass:"vol-org",attrs:{"src":require("../../assets/volunteer/org.png")}})]),_c('div',{staticClass:"content-wrap"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_vm._m(2),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"javascript:void()"},on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v(" 组织列表")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("组织详情")])])]),_vm._m(3),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"org-tab-1","role":"tabpanel","tabindex":"0"}},[_c('div',{staticClass:"rich-text",domProps:{"innerHTML":_vm._s(_vm.decodeContent(_vm.info.intro))}}),_c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"left"},[_c('button',{staticClass:"btn btn-link",on:{"click":_vm.toFavourite}},[_c('i',{class:[
                    'iconfont',
                    _vm.info.is_favourite == 1
                      ? 'icon-favourite_selected'
                      : 'icon-favourite_normal',
                  ]})])]),(_vm.info.member_id != _vm.userInfo.member_id)?_c('div',{staticClass:"right"},[(_vm.info.apply_status == -1)?_c('span',{staticClass:"state-fail"},[_vm._v("未通过审核")]):_vm._e(),(_vm.info.apply_status == 0 || _vm.info.apply_status == -1)?_c('div',{staticClass:"bottom-btn",on:{"click":_vm.toPersonLogin}},[_vm._v(" 加入组织 ")]):_vm._e(),(_vm.info.apply_status == 1)?_c('span',{staticClass:"state state-ing"},[_vm._v("审核中")]):_vm._e(),(_vm.info.apply_status == 2)?_c('div',{staticClass:"bottom-btn",on:{"click":_vm.toPersonLogin}},[_vm._v(" 通过审核 ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"org-tab-2","role":"tabpanel","tabindex":"0"}},[_c('div',{staticClass:"vol-news-list"},_vm._l((_vm.eventData.list),function(item){return _c('div',{key:item.event_id,staticClass:"vol-news-item",on:{"click":function($event){return _vm.toEventDetail(item)}}},[_c('div',{staticClass:"vol-news-pic"},[_c('img',{attrs:{"src":item.image}})]),_c('span',{staticClass:"vol-news-title"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"vol-news-time"},[_vm._v(_vm._s(item.created_at))])])}),0),_c('Pagination',{attrs:{"current":1,"pageSize":100,"hideOnSinglePage":true,"total":parseInt(_vm.eventData.total),"showQuickJumper":false,"showTotal":false,"placement":"center"},on:{"change":_vm.pageChanged}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-weibiaoti-3"}),_vm._v("所在地区")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont icon-geren"}),_vm._v("负责人")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/home"}},[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-tabs nav-red"},[_c('li',{staticClass:"nav-item"},[_c('button',{staticClass:"nav-link active",attrs:{"data-bs-toggle":"tab","data-bs-target":"#org-tab-1","type":"button","aria-selected":"true"}},[_vm._v(" 组织简介 ")])]),_c('li',{staticClass:"nav-item"},[_c('button',{staticClass:"nav-link",attrs:{"data-bs-toggle":"tab","data-bs-target":"#org-tab-2","type":"button","aria-selected":"false"}},[_vm._v(" 精彩活动 ")])])])
}]

export { render, staticRenderFns }